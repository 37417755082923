import { Box, CardMedia, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@emotion/react'
import './search.css'
import ico from './../../assets/hero/Icon feather-search.png'
// import Cat from '../PopularServices/Cat'
import DropdownWithSearch from './DropdownWithSearch'
const Search = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {t} = useTranslation()
  return (
    <>
      <Box sx={{ 
        my:1,
        p:2,
        px:0,
        borderRadius:'16px',
        backgroundColor:'#FFFFFF',
        display:'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        width: isMobile ? '100%' : '75%',
        flexDirection : isMobile && 'column',
        gap:{md:0, xs:2} 
      }} >
        <Box sx={{ width: { md: '35%' ,xs:'85%'}, display:'flex', justifyContent:'space-between' }} >
          <Typography sx={{ color: '#000' }} >{t("Hero.search")}</Typography>
          <CardMedia src={ico} component={'img'}  sx={{
            display:{md:'none', xs:'unset', },
            width: { md: '100px', xs: '25px' },
            height: { md: '65px', xs: '25px' }, objectFit: 'contain'
          }} alt="logo" />
        </Box>
        <Box sx={{ width: { md: '50%', xs: '90%' } }} position={'relative'} >
          <Box sx={{
            position: 'relative',
            zIndex: 0, }} >
            <DropdownWithSearch />
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default Search