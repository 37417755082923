import { Button, Card, CardContent, CardMedia, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const CardBlog = ({ img, Align, title, desc, path }) => {
    const navigate = useNavigate()
  return (
      <Card sx={{ maxWidth: { md: 345, xs:'100%' }, my: 1, boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.20)", }}>
          <CardMedia
              sx={{ height: 140 }}
              image={img}
              title="green iguana"
          />
          <CardContent sx={{ textAlign: 'center' }} >
              <Typography gutterBottom variant="h6" component="div" color={'#000000'} sx={{ textTransform: 'uppercase' }} >
                  {title}
              </Typography>
              <Typography variant="body2" color={'#707070'} my={1} >
                  {desc}
              </Typography>
              <Button sx={{ backgroundColor: '#FABB25', color: '#000' }} onClick={() => navigate(path)}>{'المزيد'}</Button>
          </CardContent>
      </Card>
  )
}

export default CardBlog