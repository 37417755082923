import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import { CardMedia, IconButton} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import NavDrawer from "./NavDrawer";
import { useTranslation } from "react-i18next";
import logo from "./../../assets/logo.png"
// import ico from "./../../assets/Icon awesome-user-check.png"
import BasicSelect from "./TransLang";
function NavBar() {

    const { t } = useTranslation()
    const [drawer, setDrawer] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const position = window.scrollY;
            setScrollPosition(position);
        };

        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <>
            <AppBar position="static" elevation={0}
                style={{
                    boxShadow: "rgba(255, 255, 255, 0.25) 0px 0px 12px inset",
                    backgroundColor:'#141414',
                    padding: "5px",
                    position: 'sticky',
                    top: 0,
                    zIndex: 100,
                    height: '80px',
                    justifyContent:'center'
                }} >
                <Container maxWidth={'100%'} >
                    <Toolbar disableGutters>
                        <Box sx={{
                            flexGrow: 1,
                            display: { md: "flex", xs: "none" },
                            alignItems: "center",
                            justifyContent: 'space-around'
                        }}>
                            <Box
                                sx={{
                                    display: { md: "flex", xs: "none" },
                                    gap: 6,
                                    width: '100%',
                                    justifyContent: 'flex-start',
                                    alignItems: "center",
                                }}>
                                <Link
                                    className="link"
                                    to="/"
                                    style={{
                                        textDecoration: "none",
                                        fontSize: 16,
                                        fontWeight: "700",
                                    }}
                                >
                                    {t('linksPages.home')}
                                </Link>
                                <Link
                                    className="link"
                                    to="/about"
                                    style={{
                                        textDecoration: "none",
                                        fontSize: 16,
                                        fontWeight: "700",
                                    }}
                                >
                                    {t('linksPages.aboutUs')}
                                </Link>
                                <Link
                                    className="link"
                                    to="/services"
                                    style={{
                                        textDecoration: "none",
                                        fontSize: 16,
                                        fontWeight: "700",
                                    }}
                                >
                                    {t('linksPages.OurServices')}
                                </Link>
                                {/* <Link
                                    className="link"
                                    to="/resources"
                                    style={{
                                        textDecoration: "none",
                                        fontSize: 16,
                                        fontWeight: "700",
                                    }}
                                >
                                    {t('linksPages.resources')}
                                </Link> */}
                                <Link
                                    className="link"
                                    to="/contact"
                                    style={{
                                        textDecoration: "none",
                                        fontSize: 16,
                                        fontWeight: "700",
                                    }}
                                >
                                    {t('linksPages.ContactUs')}
                                </Link>
                                <Link
                                    className="link"
                                    to="/booking"
                                    style={{
                                        textDecoration: "none",
                                        fontSize: 16,
                                        fontWeight: "700",
                                    }}
                                >
                                    {t('linksPages.booking')}
                                </Link>
                                <Link
                                    className="link"
                                    to="/start-now"
                                    style={{
                                        textDecoration: "none",
                                        fontSize: 16,
                                        fontWeight: "700",
                                    }}>
                                    {t('linksPages.startNow')}
                                </Link>
                            <BasicSelect />
                            </Box>
                        </Box>
                        <Link
                            to="/"
                            style={{
                                textDecoration: "none",
                                display:'flex'
                            }}>
                            <CardMedia src={logo} component={'img'} sx={{
                                display: {md:'flex', xs:'none'},
                                width: { md: '80%', xs: 'auto' },
                                height: { md: '65px', xs: '40px' }, objectFit: 'contain'
                            }} alt="logo" />
                        </Link>
                        <Box sx={{ width: '100%', display: { md: 'none', xs: 'flex' }, justifyContent:'space-between', alignItems:'center' }} >
                            <Box
                                sx={{
                                    display: { md: "none", xs: "flex" },
                                    justifyContent: "end",
                                }}
                            >
                                <IconButton onClick={() => setDrawer(true)}>
                                    <MenuIcon sx={{ color: '#FFF', fontSize: '2.5rem' }} />
                                </IconButton>
                            </Box>
                            <Link
                                to="/"
                                style={{
                                    textDecoration: "none",
                                    mt: '10px'
                                }}>
                                <CardMedia src={logo} component={'img'} sx={{
                                    width: {md:'100px', xs:'auto'},
                                    height: { md: '65px', xs: '40px' }, objectFit: 'contain'
                                }} alt="logo" />
                            </Link>
                            <BasicSelect />
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
            <NavDrawer drawer={drawer} setDrawer={setDrawer} />
        </>
    );
}
export default NavBar;
