import React from 'react'
import Header from '../components/glopal/Header'
import { Box, Container, Typography } from '@mui/material'

const PrivacyPolicy = () => {



    return (
        <>
            <Header path={'سياسة الخصوصية'}/>
            <Container maxWidth={'md'} >
                <Box sx={{
                    display: 'flex', flexDirection: 'column', gap: 2, py: 4,
                    justifyContent: 'space-between',
                    '@media (min-width: 1440px)': {
                        height: '50rem',
                    } }} >
                    <Typography textAlign={'center'} >
                        في Revamp، نلتزم بحماية خصوصية عملائنا وزوارنا، واستخدام خدماتنا يعني موافقتك على سياسة الخصوصية الخاصة بنا.
                    </Typography>
                    <Box sx={{ display:'flex', flexDirection:'column', gap:1 }}>
                        <Typography variant='h5' >1- جمع المعلومات:</Typography>
                        <Typography sx={{fontSize:'1rem'}} >نقوم بجمع معلومات محددة تشمل اسمك وعنوان بريدك الالكتروني ومعلومات الاتصال الأخرى التي تقدمها عند التسجيل أو عند طلب خدمة معينة. نحن نجمع هذه المعلومات بأمان لنتمكن من تقديم خدماتنا بفعالية</Typography>
                    </Box>
                    <Box sx={{ display:'flex', flexDirection:'column', gap:1 }}>
                        <Typography variant='h5' >
                            2- استخدام المعلومات:</Typography>
                        <Typography sx={{fontSize:'1rem'}} >
                            نستخدم المعلومات التي نجمعها لتحسين خدماتنا وتلبية احتياجات عملائنا. يمكن أن يشمل ذلك التواصل معك بشأن خدماتنا، وإرسال تحديثات وإشعارات مهمة، وتقديم المشورة والدعم الفني، وتحسين تجربتك العامة معنا.</Typography>
                    </Box>
                    <Box sx={{ display:'flex', flexDirection:'column', gap:1 }}>
                        <Typography variant='h5' >
                            3- مشاركة المعلومات:</Typography>
                        <Typography sx={{fontSize:'1rem'}} >نحن نحترم خصوصية عملائنا ولا نشارك أو نبيع معلوماتهم الشخصية لأطراف ثالثة بدون موافقتهم الصريحة، ما لم يكن مطلوبًا بموجب القانون أو في حالة الامتثال لطلب قانوني.</Typography>
                    </Box>
                    <Box sx={{ display:'flex', flexDirection:'column', gap:1 }}>
                        <Typography variant='h5' >4- الأمان:</Typography>
                        <Typography sx={{fontSize:'1rem'}} >خصوصيتك تعتبر أمراً مهماً بالنسبة لنا، نحن نتخذ إجراءات أمنية مناسبة لحماية المعلومات الشخصية التي نجمعها، ومع ذلك فإنه لا يوجد نظام أمان مطلق، ولا يمكننا ضمان أن المعلومات لن تتعرض للوصول غير المصرح به أو الاستخدام.</Typography>
                    </Box>
                    <Box sx={{ display:'flex', flexDirection:'column', gap:1 }}>
                        <Typography variant='h5' >5- ملفات تعريف الارتباط:</Typography>
                        <Typography sx={{fontSize:'1rem'}} >قد نستخدم ملفات تعريف الارتباط لتحسين تجربتك على موقعنا. يستخدم ملفات تعريف الارتباط لتخزين المعلومات حول تفضيلاتك. يمكنك تعطيل ملفات تعريف الارتباط في إعدادات المتصفح الخاص بك، ولكن قد يؤدي ذلك إلى تقليل وظائف Rev freelance.</Typography>
                    </Box>
                </Box>
            </Container>
        </>
    )
}

export default PrivacyPolicy
