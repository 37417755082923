import { Box, CardMedia, Container, Divider, Rating, Typography } from '@mui/material'
import React from 'react'
import Item from './Item'
import start from './../../../assets/startup-business-teamwork-meeting-concept.png'
const StartNow = () => {
    return (
        <>
            <Container maxWidth={'lg'} >
                <Box sx={{ my:2 }}>
                    <Typography variant={'h5'} color={'#000000'} mb={1} >انطلق مع Revamp نحو حلول إبداعية </Typography>
                    <Divider sx={{ borderColor: '#FABB25', borderWidth: '1px', width: '50%', mb: 1 }} />
                    <Divider sx={{ borderColor: '#FABB25', borderWidth: '1px', width: '25%' }} />
                    <Box sx={{ display:'flex', flexDirection:{md:'row', xs:'column'}, gap:2, alignItems:'center', my:2 }} >
                        <Item title={'تطبيق استراتيجيات ترويجية مبتكرة'} />
                        <Item dir={'row-reverse'} title={' الوصول إلى العملاء المستهدفين بفعالية'} />
                        <Item title={'الاستناد إلى تحليل الأداء وردود الفعل من العملاء'} />
                        </Box>
                        <Box>
                            <Box sx={{ textAlign:'center', my:1 }} >
                                <Rating name="read-only" value={5} readOnly />
                            </Box>
                            <CardMedia
                                src={start}
                                component={'img'}
                                sx={{ objectFit: 'contain', height:'350px' }}
                            />
                    </Box>
                </Box>
            </Container>
        </>
    )
}

export default StartNow