import { Box, Container, Divider, Typography } from '@mui/material'
import React from 'react'
import Card from './Card'

const Services = () => {
    return (
        <>
            <Container maxWidth={'lg'} >
                <Box sx={{ my: 2 }}>
                    <Box>
                        <Typography variant={'h5'} color={'#000000'} mb={1} >الخدمات </Typography>
                        <Divider sx={{ borderColor: '#FABB25', borderWidth: '1px', width: '50%', mb: 1 }} />
                        <Divider sx={{ borderColor: '#FABB25', borderWidth: '1px', width: '25%' }} />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: { md: 'row', xs: 'column' }, gap: 2, alignItems: 'center', my: 2 }}>
                        <Card bg={`#793F79`} color={`#ECEAEA`} description={`حملات إعلانية مبتكرة وفعالة تستهدف جمهورك المستهدف 
بدقة، مما يزيد من تفاعلهم ويعزز من مبيعاتك مع كتابة محتوى
 مميز ومتنوع لموقعك على الويب أو وسائل التواصل الاجتماعي`} num={`1`} />
                        <Card bg={`#ECEAEA`} color={`#793F79`} description={`مع فريقنا المتخصص في التصميم الجرافيكي نقدم لك تصاميم
 مبتكرة وملفتة للنظر تعكس هوية علامتك التجارية وتساعد في 
تعزيز تفاعل العملاء مع منتجاتك أو خدماتك`} num={`2`} />
                        <Card bg={`#793F79`} color={`#ECEAEA`} description={`تصميم وتطوير متاجر إلكترونية متكاملة وسهلة الاستخدام، مما
 يمكنك من عرض منتجاتك بشكل فعال وزيادة مبيعاتك 
الإنترنت`} num={`3`} />
                    </Box>
                </Box>
            </Container>
        </>
    )
}

export default Services