import { Box, Button, CardMedia, Grid, Typography, Container } from '@mui/material'
import React from 'react'
import img from './../../assets/feedback3.png'
import './style.css'
import { useNavigate } from 'react-router-dom'

const ServiceMarkit = () => {
    const navigate = useNavigate()
    return (
        <>
            <Container maxWidth={'lg'} sx={{ my:2 }}>
                <Box sx={{ textAlign: { md: 'inherit', xs: 'center' } }} >
                    <Typography mb={2} variant='h4' color={'#F0AF3C'} >{'نقدم خدمات التسويق'} <span style={{ color: '#000000' }} > الرقمي للأفراد والشركات</span> </Typography>
                </Box>
                <Grid container spacing={1}>
                    <Grid item md={6} xs={12}>
                        <Box position={'relative'}>
                                <CardMedia 
                            component={'img'}
                            src={img}
                            />
                            <div className="overlay">
                                <Button sx={{ backgroundColor: '#FABB25', color: '#000', ':hover': { backgroundColor:'#FABB25', } }} >{'خدمات الأفراد'}</Button>
                            </div>
                        </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Box position={'relative'}>
                                <CardMedia 
                            component={'img'}
                            src={img}
                            />
                            <div className="overlay">
                                <Button sx={{ backgroundColor: '#FABB25', color: '#000', ':hover': { backgroundColor:'#FABB25', } }} onClick={()=>navigate('/booking')} >{'خدمات المؤسسات'}</Button>
                            </div>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}

export default ServiceMarkit