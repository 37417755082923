import React, { useState } from 'react'
import Header from '../components/glopal/Header'
import { useTranslation } from 'react-i18next'
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, CardMedia, Container, Grid, Typography } from '@mui/material'
import FormBooking from '../components/booking/FormBooking'
import FormContact from '../components/booking/FormContact'
import InableForm from '../components/booking/InableForm'
// import Content from '../components/footer/Content'
import facebook from './../assets/footer/Icon awesome-facebook.png'
// import whatsapp from './../assets/footer/Icon simple-whatsapp.png'
import insta from './../assets/footer/Icon ionic-logo-instagram.png'
import snap from './../assets/footer/snapchat-svgrepo-com.svg'
// import twet from './../assets/footer/twitter-svgrepo-com.svg'
import tektok from './../assets/footer/tictok-svgrepo-com.svg'
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Booking = () => {
    const imgs = [
        {
            imgUrl: facebook,
            link: 'https://www.facebook.com/revampbrands/'
        },
        {
            imgUrl: snap,
            link: 'https://www.snapchat.com/add/revamp-brands'
        },
        // {
        //     imgUrl: twet,
        //     link: 'https://twitter.com/mazidagencyx'
        // },
        {
            imgUrl: tektok,
            link: 'https://www.tiktok.com/@revampbrands0'
        },
        {
            imgUrl: insta,
            link: 'https://www.instagram.com/revampbrands/'
        },
    ]
    const { t } = useTranslation()

    const [selectedForm, setSelectedForm] = useState('a');

    const handleFormChange = (value) => {
        setSelectedForm(value);
    };
    const [expandedId, setExpandedId] = useState('a');

    const handleChange = (panel) => (event, isExpanded) => {
        setExpandedId(isExpanded ? panel : null);
    };
    return (
        <>
            <Header path={t("linksPages.booking")} />
            <Container maxWidth={'lg'} >
                <Grid container sx={{ alignItems: 'flex-start', gap: { md: 0, xs: 2 } }} >
                    <Grid item md={12} xs={12}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1, my: 1 }}>
                            <Typography>موقع معتمد من وزارة التجارة بسجل تجاري رقم :</Typography>
                            <Typography><span>3550141892</span> الرقم الضريبي : <span>311125965500003</span></Typography>
                            <Typography>info@revampbrands.com</Typography>
                            <Typography>Revamp Brands</Typography>
                            <Box sx={{ color: '#Fff', display: 'flex', alignItems: 'center', gap: 2, position: 'relative' }} >
                                {
                                    imgs?.map((img, i) =>
                                        <Box sx={{ background: '#000000', p: 1, borderRadius: '8px' }} key={i} >
                                            <a href={img?.link}>
                                                <CardMedia
                                                    alt='test'
                                                    component={'img'}
                                                    src={img?.imgUrl}
                                                    sx={{ height: '25px' }}
                                                />
                                            </a>
                                        </Box>
                                    )
                                }
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Accordion key={'a'}
                            defaultExpanded={'a'}
                            expanded={'a' === expandedId}
                            onChange={handleChange('a')}
                            sx={{ m: '16px' }}
                        >
                            <AccordionSummary sx={{
                                backgroundColor: 'a' === expandedId ? '#fff' : "#fff",
                                color: 'a' === expandedId ? '#fff' : "#fff",

                            }}
                                aria-controls="panel1a-content"
                                id="panel1a-header"

                            >
                                <InableForm title={t("booking.titleBook")} handleChange={handleFormChange} selectedValue={selectedForm} formId="a" />
                            </AccordionSummary>
                            <AccordionDetails>
                                <FormBooking isDisable={selectedForm} >
                                </FormBooking>
                            </AccordionDetails>

                        </Accordion>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Accordion key={'b'}
                            defaultExpanded={'b'}
                            expanded={'b' === expandedId}
                            onChange={handleChange('b')}
                            sx={{ m: '16px' }}
                        >
                            <AccordionSummary sx={{
                                backgroundColor: 'b' === expandedId ? '#fff' : "#fff",
                                color: 'b' === expandedId ? '#fff' : "#fff",

                            }}
                                aria-controls="panel1a-content"
                                id="panel1a-header"

                            >

                                <InableForm title={t("booking.titleEmail")} handleChange={handleFormChange} selectedValue={selectedForm} formId="b" />
                            </AccordionSummary>
                            <AccordionDetails>

                                <FormContact isDisable={selectedForm} >
                                </FormContact>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
                <Box py={2} textAlign={'center'} >
                    <Button variant="contained"
                        type="submit"
                        sx={{
                            backgroundColor: '#1E8D07', ':hover': {
                                backgroundColor: '#1E8D07'
                            },
                            color: "#FFF",
                            fontWeight: 'bold',
                            width: 'fit-content',
                            textAlign: 'center',
                            my: 1
                        }}>
                        <a href="https://wa.link/u0w17n" style={{ color:'#FFF', textDecoration:'none' }} rel="noreferrer" target='_blank'>
                            {t("booking.whatsApp")}
                        </a>
                    </Button>
                </Box>
            </Container>
        </>
    )
}

export default Booking
