import React from 'react';
import Box from '@mui/material/Box';
// import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
// import i18next from 'i18next';
import TranslateIcon from '@mui/icons-material/Translate';
export default function BasicSelect() {

    // const { i18n } = useTranslation();
    // let lng = i18next.language;

    const toggleLanguage = () => {
        // const otherLng = lng === 'en' ? 'ar' : 'en';
        // i18n.changeLanguage(otherLng);
    };


    return (
        <div>
            <Box textAlign={'end'} width='fit-content' display={'flex'} >
                <Button sx={{
                    minWidth: '50px'
                }} onClick={toggleLanguage} >
                    <TranslateIcon />
                </Button>
            </Box>
        </div>
    );
}