import { Box, CardMedia } from '@mui/material'
import React from 'react'

const Card = ({review}) => {
    return (
        <>
            <Box sx={{ p: '15px', border:'1px solid #F9AF33', borderRadius:'8px', display:'flex', flexDirection:'column', gap:1, alignItems:'center', mx:2, justifyContent:'space-between', height:'100%' }} > 
            <CardMedia alt='test' component={'img'} src={review} sx={{ width:'95%' }} />
            </Box>
        </>
    )
}

export default Card