import { Box, Container, Divider, Rating, Typography } from '@mui/material'
import React from 'react'
import Slider from '../../Slider'

const Review = () => {
    return (
        <>
            <Container maxWidth={'lg'} >
                <Box sx={{ my: 2 }}>
                    <Box sx={{ mb: 2 }} >
                        <Typography variant={'h5'} color={'#000000'} mb={1} >أراء العملاء</Typography>
                        <Divider sx={{ borderColor: '#FABB25', borderWidth: '1px', width: '50%', mb: 1 }} />
                        <Divider sx={{ borderColor: '#FABB25', borderWidth: '1px', width: '25%' }} />
                    </Box>
                    <Slider state={false}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, color: '#000' }} >
                            <Typography  >كل الاحترام والتقدير لكم  شركة محترمه ومتعاونه في الخدمات التي تقدمها.</Typography>
                            <Rating sx={{ margin: 'auto' }} name="read-only" value={5} readOnly />
                            <Typography>احمد صقر</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, color: '#000' }} >
                            <Typography  >انا فخور جداً بتعاملي معكم ونشكركم على تعاونكم الدائم والخدمة المميزة التي تقدموها خدمة مميزة ومبتكرة ومتميزة جدا واتمنى لكم دوام النجاح والتفوق والتميز في العمل.</Typography>
                            <Rating sx={{ margin: 'auto' }} name="read-only" value={5} readOnly />
                            <Typography>وجدي حسبوا</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, color: '#000' }} >
                            <Typography  >شكرا شركة Revamp على حسن التعامل والاخلاق الحسنه وجوده تنفيذ الاعمال المطلوبه فى الاوقات المحددة،  والإنحاز بشكل احترافى يليق بهم.
                                كل الشكر والتقدير لحضراتكم جميعاً</Typography>
                            <Rating sx={{ margin: 'auto' }} name="read-only" value={5} readOnly />
                            <Typography>احمد المعداوي</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, color: '#000' }} >
                            <Typography  >شكرا شركة Revamp وسعيد جدا بالتعامل معاكم  والصراحة كان التعامل باحترافيه وكل احترام وارشح كل اللى يبدا في مجال الاونلاين وعندو بيزنس جديد انو يبدأ معاهم لانهم فعلاً ناس فاهمه وعندها بلان
                                بشكركم جدا واتمنلكم كل التوفيق 🌹</Typography>
                            <Rating sx={{ margin: 'auto' }} name="read-only" value={5} readOnly />
                            <Typography>شيماء سرحان</Typography>
                        </Box>
                    </Slider>
                </Box>
            </Container>
        </>
    )
}

export default Review