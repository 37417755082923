import { Container, useMediaQuery } from '@mui/material'
import React from 'react'
import { useTheme } from '@emotion/react';
import TopFooter from './TopFooter'

const Footer = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <footer style={{ padding: isMobile ? '16px' : '20px', background: '#000000', textAlign: isMobile ? 'center' : 'initial' }} >
            <Container maxWidth={'lg'}>
                <TopFooter />
            </Container>
        </footer>
    )
}

export default Footer