import { Box, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

const HeroText = () => {
    const { t } = useTranslation()
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 2, my: 2, textAlign: 'center' }} >
            <Typography variant='h5' >{t("startNow.title")}</Typography>
            <Typography>{t("startNow.supTitle")}</Typography>
        </Box>
    )
}

export default HeroText
