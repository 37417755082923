import { Box, CardMedia, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import SkillText from './SkillText'
import { useTranslation } from 'react-i18next'
import img from './../../assets/skills/Group 1820.png'
import { useTheme } from '@emotion/react'

const DescSkills = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const {t} = useTranslation()
    return (
        <>
            <Box sx={{ backgroundColor:"#FFF", borderRadius:'16px', display:'flex', flexDirection:'column', gap:1, p:'24px' }}>
                <Typography variant='h5' color={"#000000"} sx={{ fontWeight:'bold' }} >
                    {t("skills.title")}
                </Typography>
                <SkillText
                title={t('skills.skill1.title')}
                    supTitle={t("skills.skill1.desc")} 
                />
                <SkillText
                title={t('skills.skill2.title')}
                    supTitle={t("skills.skill2.desc")} 
                />
                <SkillText
                title={t('skills.skill3.title')}
                    supTitle={t("skills.skill3.desc")} 
                />
                {
                    isMobile &&
                <CardMedia
                        alt='test'
                    component={'img'}
                    src={img}
                    sx={{ width: '100%', height:'200px', objectFit:'contain' }}
                />
                }
            </Box>
        </>
    )
}

export default DescSkills