import React, { useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { services } from '../../constants';
import { useNavigate } from 'react-router-dom';
import i18next from 'i18next';

const DropdownWithSearch = () => {
    let lng = i18next.language
    const navigate = useNavigate()
    const options = services?.map((res) => ({
        value: res?.id,
        label: res?.name
    }));
    const [selectedArea, setSelectedArea] = useState(null);

    const handleChange = (event, newValue) => {
        if (newValue) {
            setSelectedArea(newValue.value);
            navigate(`/service/${newValue.value}`)
        }
    };

    return (
        <Autocomplete
            value={selectedArea ? options.find(option => option.value === selectedArea) : null}
            onChange={handleChange}
            options={options}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={selectedArea ? options.find(option => option.value === selectedArea)?.label : (lng === 'ar' ? 'الأقسام' : 'category')}
                    variant="outlined"
                    fullWidth
                    sx={{
                        width: { md: '100%', xs: '100%' },
                        borderRadius: '16px'
                    }}
                />
            )}
            sx={{
                width: { md: '100%', xs: '100%' },
                borderRadius: '16px'
            }}
        />
    );
};

export default DropdownWithSearch;
