// ParticleBackground.js
import React, { useRef, useEffect } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import * as THREE from 'three';

const Particles = () => {
    const particlesRef = useRef();

    useEffect(() => {
        const particleCount = 1500;
        const positions = new Float32Array(particleCount * 3);

        for (let i = 0; i < particleCount; i++) {
            const x = (Math.random() - 0.5) * 15;
            const y = (Math.random() - 0.5) * 15;
            const z = (Math.random() - 0.5) * 15;

            positions[i * 3] = x;
            positions[i * 3 + 1] = y;
            positions[i * 3 + 2] = z;
        }

        particlesRef.current.geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
    }, []);

    useFrame(({ clock }) => {
        const elapsedTime = clock.getElapsedTime();
        particlesRef.current.rotation.y = elapsedTime * 0.1;
    });

    return (
        <points ref={particlesRef}>
            <bufferGeometry attach="geometry" />
            <pointsMaterial attach="material" color="white" size={0.01} />
        </points>
    );
};

const ParticleBackground = () => {
    return (
        <Canvas className="particle-canvas" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
            <Particles />
        </Canvas>
    );
};

export default ParticleBackground;
