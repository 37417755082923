import { Box, Container, Typography } from '@mui/material'
import React from 'react'
import BasicAccordion from '../../BasicAccordion/BasicAccordion'
const Advantages = () => {
    return (
        <>
            <Container maxWidth={'lg'}>
                <Typography sx={{ my: 2, textAlign: { md: 'inherit', xs: 'center' }, fontSize:'bold', mt:5 }} variant='h5' >
                    <span style={{ color: '#FABB25' }} >بعض الأسئله الشائعه ؟ </span>
                </Typography>
                <Box>
                    <BasicAccordion />
                </Box>
            </Container>
        </>
    )
}

export default Advantages